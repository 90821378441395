<template>
  <div class="create">
    <header class="device-create-head">
      <div class="detail">
        <nav>
          <a @click="$router.go(-1)">
            <i class="material-icons">keyboard_arrow_left</i>All Devices
          </a>
        </nav>
        <h1>Activate Device</h1>
      </div>
    </header>
    <div class="device-wrap">
      <div class="main">
        <activatedevice></activatedevice>
      </div>
      <aside class="sidebar">
      </aside>
    </div>
  </div>
</template>

<script>
import activatedevice from '@/components/user/devices/device-activate'

export default {
  name: 'create_device',
  components: {
    activatedevice
  }
}
</script>

<style lang="scss" scoped>
.device-wrap {
  max-width: 965px;
  display: flex;
  @media(max-width: 1000px) {
    flex-direction: column;
  }
  .main {
    flex:1;
  }
  .sidebar {
    flex: 332px 0 0;
    margin-left: 32px;
    @media(max-width: 1000px) {
      margin-left: 0;
    }
  }
}

.device-create-head  {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  max-width: 965px;
  margin-bottom: 26px;
  @media(max-width: 1000px) {
    flex-direction: column;
    align-items: flex-start;
  }
  .detail {
    nav {
      i {
        font-size: 16px;
      }
      a {
        opacity: 0.6;
        color: #111215;
        font-size: 10px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 12px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
    h1 {
      margin:0;
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0.17px;
      line-height: 28px;
      margin-top: 8px;
      @media(max-width: 1000px) {
        margin-bottom: 16px;
      }
    }
  }

  .icon-btn {
    opacity: 0.6;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 12px;
    text-transform: uppercase;
    margin-left: 28px;
    @media(max-width: 1000px) {
      margin-left:0px;
      padding: 6px 12px;
    }
    i {
      font-size: 18px;
      margin-right: 6px;
    }
    &:hover {
      opacity: 1;
    }
  }
}
</style>
