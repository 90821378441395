<template>
  <swarm-widget :title="'Device Info'">
    <template slot="body">
      <span class="label">Scan QR code from device:</span>
      <qrcode-stream class="qrStyle" @decode="onDecode"></qrcode-stream>
      <form @submit.prevent="onSubmit">
        <input type="text" placeholder="Authentication Code" class="input" v-model="authCode">
        <button type="submit" class="btn-block-primary">
          <button :class="{'disabled' : !match}" class='btn-block-primary'>Activate Device</button>
        </button>
      </form>
    </template>
  </swarm-widget>
</template>

<script>
import swarmWidget from '@/components/general/widget'
export default {
  name: 'device-activate-widget',
  data() {
    return {
      authCode: ''
    }
  },
  components: {
    swarmWidget
  },
  methods: {
    onSubmit() {
      this.$store.dispatch('activateDevice',{
        authCode: this.authCode
      });
      this.authCode = '';
      this.$router.push({name: 'user devices'});
    },
    onDecode (decodedString) {
      this.authCode = decodedString
    }
  },
  computed: {
    match() {
      if(this.authCode.length) {
        return true;
      } else {
        return false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.qrStyle {
  display: flex;
  width: 350px;
  height: 350px;
  justify-content: center;
  padding: 10px;
}

</style>
